<template>
<div class="step__body">
  <component
  :is="(option.type || 'select') + 'Option'"
  :key="option.id"
  :option="option"
  v-for="option in options"/>
</div>
</template>
<script>
import SelectOption from './selectOption'
import CountOption from './countOption'
import { useStore } from 'vuex'
import { computed } from '@vue/reactivity'
export default {
  components: {
    selectOption: SelectOption,
    countOption: CountOption,
    checkOption: SelectOption
  },
  setup () {
    const store = useStore()
    const options = computed(() => {
      return store.getters['configurator/currentOptions']
    })
    return {
      options
    }
  }
}
</script>
